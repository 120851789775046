.content {
  text-align: center;
  padding: 2rem;

  .text {
    margin: 8rem auto;
    max-width: 640px;

    p {
      font-size: 1rem;
      font-weight: 300;
    }
  }

  .intro {
    margin: 8rem auto;
    max-width: 640px;

    p {
      font-size: 1.2rem;
      font-weight: 300;
    }
  }

  .image {
    position: relative;
    margin: 4rem auto 8rem;
    max-width: 800px;

    img {
      width: 100%;
      height: auto;
    }
  }

  h2 {
    margin: 4rem auto;
    max-width: 800px;
  }

  .gallery {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 800px;
    margin: auto;

    .image {
      position: relative;
      width: 100%;
      height: auto;
      margin: 4rem 0;
      overflow: hidden;
    }
  }

  .works {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 800px;
    margin: auto;

    .work {
      position: relative;
      width: 100%;
      padding-top: 50%;
      margin: 4rem 0;
      overflow: hidden;

      .featuredimage {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: all .4s;
      }

      .title {
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: 0;
        transform: translate3d(-50%,-50%,0);
      }

      a {
        &::after {
          content: none;
        }
      }

      &:hover {
        .featuredimage {
          transform: scale(1.1);
        }
      }
    }
  }
}

.featuredimage {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #000;

  .image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
    transition: all 0.4s;
  }
  
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.16);
  }

  .load & {
    .image {
      opacity: 1;
    }
  }
}
