html {
  font-size: calc(10px + 0.5vw);
}

body {
  font-family: 'Roboto', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

input,
li,
p {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 2;
  font-weight: 400;
  margin-bottom: 1rem;
  letter-spacing: 0.064rem;

  strong {
    font-weight: 700;
  }
}

h1 {
  font-family: 'Matchbook', sans-serif;
  font-size: 6rem;
  margin-bottom: 1.8rem;
  font-weight: 300;
}

h2 {
  font-family: 'Matchbook', sans-serif;
  font-size: 5rem;
  margin-bottom: 1.5rem;
  font-weight: 300;
}

h3 {
  font-family: 'Matchbook', sans-serif;
  font-size: 4rem;
  margin-bottom: 1.2rem;
  font-weight: 300;
}

p, h1, h2, h3 {
  a {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 4px;
      background-color: $blue;
      opacity: 0.64;
      transition: all .4s;
    }
    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }
}
