.linkanimation {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  pointer-events: none;
  opacity: 0;

  .line1 {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 50%;
    background-color: #000;
    transition: all 0.8s;
    transition-delay: 0s;
    opacity: 0;
  }

  .line2 {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 0;
    background-color: #000;
    transition: all 0.8s;
    transition-delay: 0.2s;
    opacity: 0;
  }

  .line3 {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 50%;
    background-color: #000;
    transition: all 0.8s;
    transition-delay: 0.4s;
    opacity: 0;
  }

  .line4 {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 0;
    background-color: #000;
    transition: all 0.8s;
    transition-delay: 0.6s;
    opacity: 0;
  }

  .linkload & {
    opacity: 1;
    .line1,
    .line3 {
      width: 100%;
      opacity: 1;
    }

    .line2,
    .line4 {
      height: 100%;
      opacity: 1;
    }
  }
}
