.footer {
  position: relative;
  min-height: 16rem;
  background-color: $lightgrey;

  .logo {
    position: absolute;
    left: 3.4rem;
    top: 50%;
    width: 8rem;
    height: 4rem;
    transform: translate3d(0,-50%,0);
    background-image: url("../img/strak-logo-black.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: auto;
    text-indent: -9000px;
  }

  .socials {
    position: absolute;
    right: 3.4rem;
    top: 50%;
    transform: translate3d(0,-50%,0);

    .social {
      position: relative;
      width: 2.6rem;
      height: 2.6rem;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      text-indent: -9000px;
      display: inline-block;

      &.instagram {
        background-image: url("../img/instagram.svg");
      }

      &.email {
        background-image: url("../img/mail.svg");
        margin-right: 2rem;
      }
    }
  }

}
