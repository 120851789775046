// breakpoints
$small: 500px;
$medium: 800px;
$large: 1200px;

$blue: #557ED8;
$lightgrey: #efefef;

body {
  text-align: center;
}

.wrapper {
  max-width: 1960px;
  width: 100%;
}
