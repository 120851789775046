.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 8rem;
  z-index: 9;
  pointer-events: none;

  .menu {
    position: absolute;
    left: 50%;
    top: 8rem;
    transform: translate3d(-50%,0,0);
    position: absolute;
    width: 80%;

    li {
      display: inline-block;
      margin: 0;
      padding: 0;

      a {
        position: relative;
        text-transform: lowercase;
        font-size: 1rem;
        font-weight: 300;
        display: block;
        padding: 1rem 0;
        color: #fff;
        letter-spacing: 0.32rem;
        pointer-events: visible;
        text-align: center;
        margin: 0 1rem;

        &::after {
          content: "";
          width: 0;
          height: 2px;
          position: absolute;
          left: -.2rem;
          bottom: 0.64rem;
          background-color: #fff;
          transition: all .2s;
          opacity: 0.32;
        }

        &.active {
          &::after {
            width: 100%;
            opacity: 1;
          }
        }

        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
    }
    @media screen and (min-width: $small) {
      text-align: left;
      left: 2.4rem;
      top: 50%;
      transform: translate3d(0,-50%,0);
      width: auto;
    }
  }

  .logo {
    position: absolute;
    left: 50%;
    top: 3rem;
    width: 10rem;
    height: 5rem;
    transform: translate3d(-50%,0,0);
    background-image: url("../img/strak-logo.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: auto;
    @media screen and (min-width: $small) {
      left: auto;
      right: 3.4rem;
      top: 50%;
      transform: translate3d(0,-50%,0);
    }
  }
}
