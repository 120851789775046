.carousel {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #000;
  overflow: hidden;
  cursor: none;
  z-index: 8;

  .works {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.8s;
  }

  .work {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    perspective: 2400px;
    transition: all 0.4s;

    &:nth-child(1) {
      left: 0;
      z-index: 3;
      pointer-events: auto;
    }

    &:nth-child(2) {
      left: 105%;
      z-index: 2;
      pointer-events: none;
    }

    &:nth-child(3) {
      left: 0;
      z-index: 1;
      pointer-events: none;
    }

    .title {
      position: absolute;
      left: 50%;
      bottom: 4rem;
      transform: translate3d(-50%,0,0);
      color: #fff;
      transition: transform 0.2s;

      a {
        &::after {
          content: none;
        }
      }

      &:hover {
        transform: translate3d(-50%,0,0) scale(1.032);
      }
    }

    .featuredimage {
      position: absolute;
      left: -5%;
      top: -5%;
      width: 110%;
      height: 110%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      //perspective-origin: bottom;
      //transition: all .4s;
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.16);
      }
    }
  }

  .strak-next {
    position: absolute;
    top: 50%;
    right: 4rem;
    width: 6rem;
    height: 6rem;
    background-image: url("../img/strak-next-arrow.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 2rem auto;
    z-index: 10;
    pointer-events: none;
    border: 1px solid #fff;
    border-radius: 50%;

    &.hover {
      transition: transform 0.2s;
      transform: scale(1.64);
      background-image: none;
    }
  }

  .load & {
    .works {
      opacity: 1;
    }
  }
}
